import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import AutocompleteInput from './../ui/AutocompleteInput';
import _ from 'underscore';

import config from './../../config';
import helpers from './../../lib/helpers';

function MultiSelect(props) {
	const history = useHistory();
	const [listData, setListData] = useState([]);
	const [inputValue, setInputValue] = useState('');

	let urlFrags = _.compact(history.location.pathname.split('/'));

	let buildUrl = [];

	useEffect(() => {
		if (props.value && (typeof props.value == 'string') && listData.length == 0) {
			if (helpers.isNumber(props.value.split(',').join(''))) {
				fetch(config.apiRoot+'/api/'+props.filter.selectOptions.endpoint+'?ids='+props.value)
					.then(res => res.json())
					.then((json) => {
						setListData(json.results.map((item) => {
							return {
								id: item[props.options.valueField],
								label: item[props.options.labelField]
							}
						}))
					});
			}
			else if (props.options.enableTextInput) {
				setInputValue(props.value);
			}
		}
		else if (props.value && Array.isArray(props.value)) {
			setListData(props.value);
		}

	}, [props.value]);

	return (
		<div className="multi-select">
			<AutocompleteInput inputClassName="form-control"
				responseDataField="results"
				searchUrl={config.apiRoot+'/api/'+props.filter.selectOptions.endpoint+'?'+props.filter.selectOptions.filterParam+'=$s'+(props.filter.options && props.filter.options.extraParams ? '&'+props.filter.options.extraParams.join('&') : '')}
				emptySearchUrl={config.apiRoot+'/api/'+props.filter.selectOptions.endpoint+'?'+(!props.filter.selectOptions.disableLargeList ? 'limit=500' : '')+(props.filter.options && props.filter.options.extraParams ? '&'+props.filter.options.extraParams.join('&') : '')}
				onChange={(event) => {
					setInputValue(event.target.value);

					if (props.onChange && props.options.enableTextInput) {
						props.onChange({
							value: event.target.value
						});
					}
				}}
				inputName="searchBoxInput"
				value={inputValue}
				type="text"
				valueField="name"
				onItemSelect={(event) => {
					if (props.options.singleSelect) {
						let _listData = [{
							id: event[props.options.valueField],
							label: event[props.options.labelField]
						}];

						setListData(_listData);

						if (props.onChange) {
							props.onChange({
								value: _listData
							});
						}

						//setTimeout(() => setInputValue(''), 100);
					}
					else {
						let _listData = JSON.parse(JSON.stringify(listData));

						_listData = Array.isArray(_listData) ? _listData : [];

						_listData.push({
							id: event[props.options.valueField],
							label: event[props.options.labelField]
						});

						_listData = _.uniq(_listData, (item) => item.id);

						setListData(_listData);

						if (props.onChange) {
							props.onChange({
								value: _listData
							});
						}

						//setTimeout(() => setInputValue(''), 100);
					}
				}}
				placeholder={props.filter.label}
				minChars={0}
				selectedItemClass="active"
				disableAutoFill={false}
				disableList={props.options.enableTextInput}
				listLabelFormatFunc={props.filter.selectOptions.listLabelFormatFunc || ((item) => <div>
					{item[props.options.labelField]}
				</div>)} />

			{
				listData.length > 0 &&
				<div className="selections">
					{
						listData.map((item, index) => <button key={index}
							title={item.label}
							type="button"
							className="btn btn-sm btn-primary"
							onClick={() => {
								let _listData = JSON.parse(JSON.stringify(listData));

								_listData = _.filter(_listData, (listItem) => listItem.id != item.id);


								_listData = _.uniq(_listData, (item) => item.id);

								setListData({
									value: _listData
								})

								if (props.onChange) {
									props.onChange({
										value: _listData
									});
								}
							}}
						>
							{item.label} <span aria-hidden="true">&times;</span>
						</button>)
					}
				</div>
			}
		</div>
	)
}

export default MultiSelect;
