import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import _ from 'underscore';

import config from './../../config';

function SiteTitle(props) {
	useEffect(() => {
		document.title = config.siteTitle+(props.title && props.title != '' ? ' | '+props.title : '');
	});

	return null
}

export default SiteTitle;
