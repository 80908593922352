import React, { Component, createRef } from 'react';
import { Link } from "react-router-dom";
import _ from 'underscore';

import helpers from '../../lib/helpers';

class AutocompleteInput extends Component {
	constructor(props) {
		super(props);

		this.popupContent = createRef();

		this.inputValueChangeHandler = this.inputValueChangeHandler.bind(this);
		this.inputFocusHandler = this.inputFocusHandler.bind(this);
		this.inputBlurHandler = this.inputBlurHandler.bind(this);
		this.inputKeyDownHandler = this.inputKeyDownHandler.bind(this);

		this.state = {
			inputValue: props.value || '',
			data: [],
			listIndex: -1
		};
	}

	componentDidMount() {
		let _app = this;
		document.addEventListener('mousedown', (event) => {
			if (this.popupContent.current && (this.popupContent.current.contains(event.target)) || this.popupContent.current == event.target) {
				event.stopPropagation();

				return;
			}
			else {
				this.cancel();
			}
		});
	}

	componentWillReceiveProps(props) {
		if (props.value != this.state.inputValue) {
			this.setState({
				inputValue: props.value
			});
		}
	}

	inputValueChangeHandler(event) {
		this.cancelled = false;

		event.persist();

		if (event.target.value == '') {
			if (this.props.minChars == 0) {
				this.fetchData('');
			}

			this.setState({
				inputValue: '',
				data: [],
				listIndex: -1,
				typeFilter: null
			});

			if (this.props.onChange) {
				this.props.onChange({
					target: {
						name: this.props.inputName || '',
						value: ''
					}
				});
			}
		}
		else {
			this.setState({
				inputValue: event.target.value,
				typeFilter: null
			}, function() {
				this.fetchData(this.state.inputValue);

				if (this.props.onChange) {
					this.props.onChange(event);
				}
			}.bind(this));
		}
	}

	inputKeyDownHandler(event) {
		this.cancelled = false;

		event.persist();

		if (event.keyCode == 27) { // escape
			this.cancel();
		}

		if (event.keyCode == 38 && this.props.valueField) { // upp
			if (this.state.listIndex > 0) {
				this.setState({
					listIndex: this.state.listIndex-1,
					inputValue: this.assignInputValue(this.props.valueField ? this.state.data[this.state.listIndex-1][this.props.valueField] : this.state.data[this.state.listIndex-1])
				}, function() {
					if (this.props.onChange) {
						this.props.onChange(event);
					}
				}.bind(this));
			}
		}
		if (event.keyCode == 40 && !this.props.disableAutoFill) { // niður
			if (this.state.listIndex < this.state.data.length && this.state.data[this.state.listIndex+1]) {
				this.setState({
					listIndex: this.state.listIndex+1,
					inputValue: this.assignInputValue(this.props.valueField ? this.state.data[this.state.listIndex+1][this.props.valueField] : this.state.data[this.state.listIndex+1])
				}, function() {
					if (this.props.onChange) {
						this.props.onChange(event);
					}
				}.bind(this));
			}
		}
		if (event.keyCode == 13) { // enter
			this.cancelled = true;

			if (this.state.listIndex > -1 && !this.props.disableAutoFill) {
				if (this.props.onItemSelect) {
					this.props.onItemSelect(this.state.data[this.state.listIndex])
				}

				this.setState({
					listIndex: -1,
					data: []
				});
			}
			else {
				this.setState({
					listIndex: -1,
					data: []
				}, function() {
					if (this.props.onEnter) {
						this.props.onEnter();
					}
				}.bind(this));
			}
		}

	}

	inputFocusHandler() {
		this.refs.inputField.select();

		if (this.props.minChars == 0 && (this.state.inputValue == '' || !this.state.inputValue)) {
			this.cancelled = false;

			this.fetchData('');
		}
	}

	inputBlurHandler() {
		return
		this.cancel();
	}

	cancel() {
		this.cancelled = true;

		setTimeout(function() {
			this.setState({
				data: [],
				listIndex: -1,
				typeFilter: null
			});
		}.bind(this), 200);
	}

	assignInputValue(value) {
		if (this.props.disableAutoFill) {
			return;
		}

		var inputValue = this.state.inputValue || '';

		var ret = '';

		if (inputValue.indexOf(',') > -1) {
			var inputValues = inputValue.split(',');
			inputValues[inputValues.length-1] = value;

			ret = inputValues.join(',');
		}
		else {
			ret = value;
		}
		return ret;
	}

	itemClickHandler(item, dataItem) {
		if (this.props.onItemSelect) {
			this.props.onItemSelect(dataItem)
		}

		this.setState({
			inputValue: this.assignInputValue(item)
		}, function() {
			if (this.props.onChange) {
				this.props.onChange({
					target: {
						name: this.props.inputName || '',
						value: this.state.inputValue
					}
				});
			}
		}.bind(this));

		this.cancel();
	}

	fetchData(str) {
		if (this.waitingForFetch || (this.props.minChars && this.props.minChars > 0 && str.length < this.props.minChars) || this.props.disableList) {
			return;
		}

		this.waitingForFetch = true;

		fetch(
			this.props.minChars == 0 && str == '' && this.props.emptySearchUrl ? this.props.emptySearchUrl :
			this.props.searchUrl.replace('$s', str)+(this.state.typeFilter ? '&type='+this.state.typeFilter : '')
		)
			.then(function(response) {
				return response.json()
			})
			.then(function(json) {
				if (!this.cancelled) {
					this.setState({
						data: this.props.dataFormatFunc ? this.props.dataFormatFunc(json[this.props.responseDataField || 'data']) : json[this.props.responseDataField || 'data'],
						listIndex: -1
					});
				}
				this.waitingForFetch = false;
			}.bind(this))
			.catch(function(ex) {
				//console.log('parsing failed', ex)
			});

		// sæki allar týpes
		fetch(this.props.searchUrl.replace('$s', str))
			.then(function(response) {
				return response.json()
			})
			.then(function(json) {
				let data = this.props.dataFormatFunc ? this.props.dataFormatFunc(json[this.props.responseDataField || 'data']) : json[this.props.responseDataField || 'data'];

				let types = _.uniq(_.pluck(data, 'type'));

				this.setState({
					typesFilterData: json.entry_types
				})
			}.bind(this))
			.catch(function(ex) {
				//console.log('parsing failed', ex)
			});
	}

	render() {
		let items = this.state.data.map(function(item, index) {
			return <Link to={helpers.routerUrls[item.type]+item.id} key={index} className={'list-group-item item'+(this.state.listIndex == index ? ' '+(this.props.selectedItemClass || 'selected') : '')}
				key={index}
				onClick={function(event) {
					event.preventDefault();
					this.itemClickHandler(this.props.valueField ? item[this.props.valueField] : item, item);
				}.bind(this)} >
				{
					this.props.listLabelFormatFunc ? this.props.listLabelFormatFunc(item) : this.props.valueField ? item[this.props.valueField] : item
				}
			</Link>
		}.bind(this));

		if (items.length > 0) {
			document.body.classList.add('autocomplete-open');
		}
		else {
			document.body.classList.remove('autocomplete-open');
		}

		return <div ref={this.popupContent} className={'autocomplete-input'+(items.length > 0 ? ' open' : '')}>
			<input className={this.props.inputClassName}
				ref="inputField"
				type="text"
				name={this.props.inputName || ''}
				value={this.state.inputValue}
				onChange={this.inputValueChangeHandler}
				onFocus={this.inputFocusHandler}
				onBlur={this.inputBlurHandler}
				onKeyDown={this.inputKeyDownHandler}
				placeholder={this.props.placeholder} />
			{
				items.length > 0 &&
				<div className="autocomplete-list-container">
					<div className={this.props.containerClass}>
						{
							this.state.typesFilterData && this.state.typesFilterData.length > 0 &&
							<div className="mt-2 mb-1 mt-sm-4 mb-sm-4">
								{
									this.state.typesFilterData && this.state.typesFilterData.map((type, index) => <button key={index} className="btn btn-primary btn-sm mr-1 mb-1" onClick={() => this.setState({
										typeFilter: type.key
									}, () => this.fetchData(this.state.inputValue))}>{helpers.typesPlural[type.key]}</button>)
								}
							</div>
						}

						<div className="autocomplete-list list-group">
							{
								this.props.headerText &&
								<div className="list-group-item list-group-item-light">
									<small>{this.props.headerText}</small>
								</div>
							}
							{items}
						</div>
					</div>
				</div>
			}
		</div>
	}
}

export default AutocompleteInput;
