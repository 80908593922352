import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import _ from 'underscore';

import helpers from './../../lib/helpers';

function ListHeader(props) {
	return (
		<p className="text-right"><small>{props.resultCount+(props.resultCount.toString().substr(-1) == '1' && props.resultCount.toString().substr(-2) != '11' ? ' '+window.l('færsla') : ' '+window.l('færslur'))}</small></p>
	)
}

export default ListHeader;
