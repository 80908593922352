import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import helpers from './../../lib/helpers';

function SortTable(props) {
	const [sorting, setSorting] = useState(null);
	const [reverseSorting, setReverseSorting] = useState(false);

	const history = useHistory();

	useEffect(() => {
		let params = helpers.parseSearchParams(props.searchParams);

		if (params.rodun) {
			setSorting(params.rodun.replace('-', ''));

			setReverseSorting(params.rodun.substr(0, 1) == '-');
		}

	}, [props.searchParams]);

	return (
		<table className={'table '+(props.className || '')}>
			<thead>
				<tr>
					{
						props.headers.map((item, index) => <th className={(item.sortValue ? 'sortable' : '')}
							key={index}
							width={item.width || null}
							onClick={() => {
								if (!item.sortValue) {
									return;
								}
								else {
									let _sorting;
									let _reverseSorting;

									_sorting = item.sortValue;

									if (item.sortValue == sorting) {
										_reverseSorting = !reverseSorting;
									}
									else {
										_reverseSorting = false;
									}

									setSorting(_sorting);
									setReverseSorting(_reverseSorting);

									let sortValue = (_reverseSorting ? '-' : '')+_sorting;

									history.replace(history.location.pathname+helpers.updateSearchParams(props.searchParams, {
										rodun: sortValue,
										sida: null
									}));

									if (props.onSort) {
										props.onSort((_reverseSorting ? '-' : '')+_sorting);
									}
								}
							}}
						>
							<span className={item.sortValue ? 'clickable' : ''}>
								{item.label}
							</span>
							{
								item.sortValue &&
								<span dangerouslySetInnerHTML={{__html: (item.sortValue == sorting ? (reverseSorting ? '&#8613;' : '&#8615;') : '')}} />
							}
						</th>)
					}
				</tr>
			</thead>
			<tbody>
				{
					props.children
				}
			</tbody>
		</table>
	)
}

export default SortTable;
