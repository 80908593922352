import React from 'react';
import LoadingBar from './LoadingBar';

function Loader(props) {
	return <div className="loading">
		<LoadingBar />
	</div>
}

export default Loader;
