import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Breadcrumb from './Breadcrumb';

function ContentWrapper(props) {
	return (

			<div className="content-container">

				<Breadcrumb />

				 {
					 props.children
				 }

			</div>

	)
}

export default withRouter(ContentWrapper);
